import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useTranslation } from "react-i18next";

import CorrectDate from "../../components/correctDate";

import { LuDownload } from "react-icons/lu";
import { IoIosSearch } from "react-icons/io";

export default function News(props) {
  const { t, i18n } = useTranslation("ir", { keyPrefix: "publication.news" });
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "DREIT Buy Back - News Release";
  }, []);

  const url = process.env.REACT_APP_API_URI + process.env.REACT_APP_nrl;

  const fetchData = async () => {
    try {
      const response = await Axios.get(
        url + "/getNews/" + i18n.language + "/" + searchtxt,
      );
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  useEffect(() => {
    //console.log(data);
  }, [data]);

  const [searchtxt, setSearchtxt] = useState("all");

  const searchNews = (e) => {
    if (e.key == "Enter") {
      if (searchtxt != "all") {
        fetchData();
        setSearchtxt("all");
      }
    } else {
      setSearchtxt(e.target.value);
    }
  };

  return (
    <section
      id="newsrelease"
      className="px-4 py-16 text-gray-500 sm:px-20 lg:px-32 lg:py-24"
    >
      {/*------------------------------------*/}
      <div className="mb-4 w-full sm:mb-8 lg:mb-16">
        <h1 className="text-3xl font-semibold text-gold">{t("title")}</h1>
      </div>
      <div className="flex justify-end">
        <div className="flex w-full items-center gap-2 rounded-full bg-gold/50 px-4 py-2 sm:w-1/2 lg:w-1/4">
          <div className="text-lg">
            <IoIosSearch />
          </div>
          <input
            type="text"
            placeholder={t("search")}
            className="w-full bg-transparent placeholder:text-gray-500 focus:outline-none"
            onKeyDown={searchNews}
            onChange={searchNews}
            value={searchtxt == "all" ? "" : searchtxt}
          />
        </div>
      </div>
      <div className="py-2 lg:p-4">
        <table className="w-full table-fixed border-separate border-spacing-x-2">
          <thead>
            <tr className="font-medium text-gold">
              <th className="w-[25%] border-b-2 border-[#F2E5CF] p-2 text-left sm:w-[20%] lg:w-[15%]">
                {t("date")}
              </th>
              <th className="border-b-2 border-[#F2E5CF] p-2 text-left">
                {t("ntitle")}
              </th>
              <th className="w-[25%] border-b-2 border-[#F2E5CF] p-2 text-right sm:w-[20%] lg:w-[15%]">
                {t("download")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <tr className="border-b-2 border-[#F2E5CF]">
                <td className="p-2">
                  {CorrectDate(d.releaseDate, "f", "", i18n.language)}
                </td>
                <td className="p-2 text-left">{d.title}</td>
                <td className="p-2">
                  <a
                    href={`https://dreitbb.blob.core.windows.net/news/${d.file}`}
                    download={true}
                    target="_blank"
                  >
                    <div className="flex w-full items-center justify-end gap-1 text-blueD hover:font-semibold">
                      <LuDownload />
                      {t("file")}
                    </div>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
